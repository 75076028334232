import actionTypes from '../constants';


const initialState = {

}

export default function realTimeData(state=initialState, action){
  switch (action.type) {
    case actionTypes.REALTIME_DATA_REQUEST:
      return {...state,request:action.request};
    case actionTypes.REALTIME_DATA_RECEIVED:
      return {...state,...action.data};
    case actionTypes.REALTIME_DATA_FAILURE:
      return {...state,...action.error};
    default:
      return state
  }
}


// © plusound 2020
