import actionTypes from '../constants';
import ApiMessenger from '../helpers/services/api';
//import history from '../helpers/history';





//create instance of ApiMessenger
let api = new ApiMessenger();








export function sendMessage(data, config){
  return dispatch => {
    dispatch(request(data))

    if(config.category==='contact'){
      api.contact(data).then((response)=>{
        dispatch(success(response.data.message))
      }).catch((err)=>{
        if(!err.response){
          dispatch(failure("Network Error"));
        }else{
          dispatch(failure(err.response.data.error));
        }
      })
    }else if(config.category==='career'){
      api.careerApplication(data).then((response)=>{
        dispatch(success(response.data.id))
      }).catch((err)=>{
        if(!err.response){
          dispatch(failure("Network Error"));
        }else{
          dispatch(failure(err.response.data.error));
        }
      })
    }

  }

  function request(data){return {type:actionTypes.SEND_MESSAGE_REQUEST, data}};
  function success(data){return {type:actionTypes.SEND_MESSAGE_SUCCESS, data}};
  function failure(error){return {type:actionTypes.SEND_MESSAGE_FAILURE, error}};
}
