import React, {PureComponent} from 'react';
import {PageHeader} from '../components';
import {LandingWrapper} from './wrappers';
import {connect} from 'react-redux';
import {sendMessage} from '../actions';
import application from '../assets/images/application.svg';
import speaker from '../assets/images/speaker.svg';
import manager from '../assets/images/manager.svg';
import social from '../assets/images/social.svg';
import team from '../assets/images/team.svg';
import pay from '../assets/images/pay.svg';
import people from '../assets/images/people.svg';
let careers = [
  {
    name:'Music Producer',
    open:true,
    lead:'Apply today to join our music production team.',
    desc:'We are looking for a young and talented music producer who can make beats and record vocals for upcoming artists from diverse genre of music.',
    img:speaker,
    location:'Lagos, NG',
    slug:'music-producer',
    experience:[
      {
        name:'Digital production',
        level:70
      },
      {
        name:'Mixing & Mastering',
        level:80
      },
      {
        name:'Musical instruments',
        level:30
      }
    ]
  },
  {
    name:'Music Manager',
    open:false,
    lead:'Apply today to join our music management team',
    desc:'We need a professional who is experiened in artist management and music distribution.',
    img:manager,
    location:'Lagos, NG',
    slug:'music-manager',
    experience:[
      {
        name:'Artist(s) management',
        level:80
      },
      {
        name:'Music distribution',
        level:80
      }
    ]
  },
  {
    name:'Social Media Manager',
    open:false,
    lead:'Apply today to join our digital marketing team',
    desc:'We need a professional with good communication skill, who can create contents to improve our brand and interact with the audience in our social community.',
    img:social,
    location:'Remote',
    slug:'sm-manager',
    experience:[
      {
        name:'Social media management',
        level:70
      },
      {
        name:'Digital marketing',
        level:80
      },
      {
        name:'Content creation',
        level:70
      },
      {
        name:'Good communication skill',
        level:80
      }
    ]
  }
];
class Career extends PureComponent{
  componentDidMount(){
    let {slug} = this.props.match.params;
    if(slug){
      careers.map((career)=>{
        if(career.slug === slug) return this.setState({career})
        return true
      })
    }
  }
  state = {
    career:null
  }
  render(){
    let {career} = this.state;
    let {
      sending,
      sent,
      message,
      error
    } = this.props.data.contact;
    return(
      <LandingWrapper pageTitle="Careers">
      {
        career?
        <>
        <PageHeader
          title={career.name}
          lead={career.lead}
          buttonText="Apply"
          buttonLink="#apply"
          img={career.img}
          breadCrumb={[
            {title:'Careers', link:'/careers', active:false},
            {title:career.name, link:`/careers/${career.slug}`, active:true}
          ]}
        />
        <section className="bg-overlay-content container bg-secondary rounded-lg box-shadow py-4 px-4 mb-7" style={{marginTop:'-230px'}}>
          <div className="row">
            <div className="col-md-7 mt-md-0 pt-md-0">
              <div className="py-2" style={{marginTop:'50px'}}>
                <div className="pb-3">
                  <h3 className="h5 pb-2">Role Description</h3>
                  <p>{career.desc}</p>
                </div>
                <div className="pb-4">
                  <h3 className="h5 pb-2">Experience</h3>
                  <div className="col-lg-8 col-md-7">
                  {
                    career.experience && career.experience.length?
                    career.experience.map((item, i)=>
                    <div className="d-flex align-items-center mb-2" key={i}>
                      <div className="text-nowrap mr-3"><span className="d-inline-block align-middle">{item.name}</span></div>
                      <div className="w-100">
                        <div className="progress" style={{height: 4}}>
                          <div className={`progress-bar ${item.level>=80?'bg-success':(item.level>30&&item.level<=79)?'bg-light-success':'bg-warning'}`} role="progressbar" style={{width:`${item.level}%`}} aria-valuenow={item.level} aria-valuemin={0} aria-valuemax={100} />
                        </div>
                      </div><i className="fe-star font-size-sm ml-1" /><span className="ml-3">5</span>
                    </div>
                  )
                  :
                  null
                  }
                  </div>
                </div>
                <div className="pb-3">
                  <h3 className="h5 pb-2">Location</h3>
                  <p>{career.location}.</p>
                </div>
              </div>
            </div>
            <div className="col-md-5 mt-2 pt-3 mt-md-0 pt-md-0" id="apply"><grammarly-extension style={{position: 'absolute', top: 0, left: 0, pointerEvents: 'none'}} className="cGcvT" />
              <div className="bg-light py-5 px-4 rounded-lg">
              {
                !sent?
                <div className="px-xl-3">
                  <h3 className="h4 pb-2">Fill in your details</h3>
                  <form className="needs-validation" method="post" noValidate onSubmit={this.handleSubmission}>
                    {
                      error?
                      <div className="alert alert-danger alert-dismissible fade show" role="alert">
                        <span className="font-weight-medium">{error}</span>
                      </div>
                      :
                      null
                    }
                    <div className="form-group">
                      <label htmlFor="name">Your name<span className="text-danger">*</span></label>
                      <input className="form-control" type="text" required id="name" ref={input=> this.name = input}/>
                      <div className="invalid-feedback">Please enter your name!</div><small className="form-text text-muted">Enter your full name.</small>
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Your email<span className="text-danger">*</span></label>
                      <input className="form-control" type="email" required id="email" ref={input=> this.email = input} />
                      <div className="invalid-feedback">Please provide valid email address!</div><small className="form-text text-muted">Enter an email where we can reach you.</small>
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Your phone<span className="text-danger">*</span></label>
                      <input className="form-control" type="number" required id="phone" ref={input=> this.phone = input} placeholder="+234 000000000"/>
                      <div className="invalid-feedback">Please provide valid phone number!</div><small className="form-text text-muted">Enter an phone where we can reach you. (Inc. country code)</small>
                    </div>
                    <div className="form-group">
                      <label htmlFor="instagram">Instagram Username</label>
                      <input className="form-control" type="text" id="instagram" ref={input=> this.instagram = input}/>
                      <small className="form-text text-muted">Enter a link to your Instagram profile.</small>
                    </div>
                    <div className="form-group">
                      <label htmlFor="linkedin">LinkedIN Profile URL</label>
                      <input className="form-control" type="text" id="linkedin" ref={input=> this.linkedin = input}/>
                      <small className="form-text text-muted">Enter a link to your LinkedIN profile.</small>
                    </div>
                    <div className="form-group">
                      <label htmlFor="message">Message<span className="text-danger">*</span></label>
                      <textarea className="form-control" rows={6} required id="message" spellCheck="false" defaultValue={"I'm interested in this position."} ref={input=> this.message = input}/>
                      <div className="invalid-feedback">Please write a review!</div><small className="form-text text-muted">Enter your message.</small>
                    </div>
                    <button className="btn btn-primary btn-shadow btn-block" type="submit" disabled={sending||false}>
                    {
                      sending?
                      <>
                        <span className="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true" />
                        Applying...
                      </>
                      :
                      "Apply now"
                    }
                    </button>
                  </form>
                </div>
                :
                <div className="px-xl-3">
                  <h3 className="h4 pb-2">Application Received <i className="fe-check-circle h4 text-success"/></h3>
                  <p className="font-size-sm mb-4">
                    Thank you. Your application has been received. Continue to check your email for a response from our recruiting team.
                   </p>
                   <p>#{message}</p>
                   <h6 className="text-muted">What to expect</h6>
                   <ul className="list-unstyled font-size-sm">
                     <li><i className="fe-check-circle text-success" /> Application received</li>
                     <li><i className="fe-rotate-cw text-warning" /> Response and Interview</li>
                   </ul>
                   <small className="text-muted">Have a question? Email us: </small><small>ayodeji@plusound.com</small>
                </div>
              }
              </div>
            </div>
          </div>
        </section>
        </>
        :
        <>
        <PageHeader
          title="Join our team"
          lead="Apply today to work with us."
          buttonText="See Job Openings"
          buttonLink="#open-positions"
          img={application}
        />
        <section className="container">
          <div className="bg-light rounded box-shadow py-6 px-3">
            <div className="row align-items-center">
              <div className="col-lg-5 offset-lg-1 order-lg-2 pb-5 pb-lg-0 text-center text-lg-left">
                <h4 className="">Why work with us<span className="text-muted"> ?</span></h4>
                <p className="mb-0">Work with a team of productive and friendly people, who dedicate their time and skills to build solutions for Independent artists.</p>
              </div>
              <div className="col-lg-6 order-lg-1">
                <div className="row">
                  <div className="col-sm-4 mb-2 pb-4 mb-sm-0 pb-sm-0">
                    <div className="px-2 text-center"><img className="bg-light mb-2 opacity-50" width={70} src={team} alt="Team"/>
                      <p className="font-size-sm font-weight-medium mb-0 pt-1">Friendly team</p>
                    </div>
                  </div>
                  <div className="col-sm-4 mb-2 pb-4 mb-sm-0 pb-sm-0">
                    <div className="px-2 text-center"><img className="bg-light mb-2 opacity-50" width={70} src={pay} alt="Pay"/>
                      <p className="font-size-sm font-weight-medium mb-0 pt-1">Reasonable wage</p>
                    </div>
                  </div>
                  <div className="col-sm-4 mb-2 pb-4 mb-sm-0 pb-sm-0">
                    <div className="px-2 text-center"><img className="bg-light rounded-circle mb-2 opacity-50"  width={70} src={people} alt="People"/>
                      <p className="font-size-sm font-weight-medium mb-0 pt-1">Impact lives</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-secondary pt-5 pb-3 pt-md-6 pb-md-4 pt-lg-7 pb-lg-7 mb-7" style={{marginTop:'100px'}} id="open-positions">
          <div className="container pt-3 pt-md-0">
            <h2 className="pt-4 mb-5">Open Positions</h2>
            <div className="tab-content">
              {
                careers.map((career, i)=>
                <div className="border-top py-4" key={i}>
                  <div className="row py-md-2">
                    <div className="col-lg-2 col-sm-3">
                      <div className="h6 text-body mb-0 py-4">{career.location}</div>
                    </div>
                    <div className="col-sm-4 offset-lg-1">
                      <div className="h6 text-body mb-0 py-4">{career.name}</div>
                    </div>
                    <div className="col-sm-5 col-md-5 col-lg-3 offset-lg-2 text-right">
                      <div className="mb-0 py-3">
                        <a className={`btn btn-primary ${!career.open?'disabled':''}`} href={`/careers/${career.slug}`}>{!career.open?'Not open':'Apply now'}</a>
                      </div>
                    </div>
                  </div>
                </div>
              )
              }
            </div>
          </div>
        </section>
        </>
      }
      </LandingWrapper>
    );
  }
  handleSubmission=(e)=>{
    e.preventDefault();
    let {
      name,
      email,
      phone,
      instagram,
      linkedin,
      message
    } = this;
    let required_form_data = [
      name,
      email,
      phone,
      message
    ]
    if(validateInput(required_form_data)){
      let careerApplication = {
        name:name['value'],
        email:email['value'],
        phone:phone['value'],
        instagram:instagram['value'],
        linkedin:linkedin['value'],
        message:message['value'],
        type:this.state.career.name
      }
      let {sendMessage} = this.props;
      sendMessage(careerApplication, {category:'career'});
    };
    /** validator **/
    function validateInput(inputArray){
      let err = []

      inputArray.map((input)=>{
        return !input.validity.valid?
        err.push(input)
        :
        null
      })
      if(err.length){
        return false
      }else{
        return true
      }
    }
  }
}

let mapStateToProps = state => (
  {
    data:state
  }
);
let mapDispatchToProps = {
  sendMessage
}
export default connect(mapStateToProps, mapDispatchToProps)(Career);
//@Plusound 2021
