import axios from 'axios';

const firebaseConfig = {
  apiKey: "AIzaSyCyNYGO0ql_3FHCXt7dKztrBeWxspD3O6M",
  authDomain: "plusound-a9d7b.firebaseapp.com",
  databaseURL: "https://plusound-a9d7b.firebaseio.com",
  projectId: "plusound-a9d7b",
  storageBucket: "plusound-a9d7b.appspot.com",
  messagingSenderId: "993771587646",
  appId: "1:993771587646:web:ad36a6cc8c5df22429a96f",
  measurementId: "G-40DJ72Z58R"
};




const plusoundIdenityApi = axios.create({
  baseURL: 'https://plusound-identity-api.ue.r.appspot.com/v1',
  timeout: 15000
})



const plusoundFrontEndServices = axios.create({
  baseURL: 'https://plusound-frontend-services.herokuapp.com/v1'
})

// const plusoundFrontEndServices = axios.create({
//   baseURL: 'http://localhost:3000/v1'
// })



export {
  firebaseConfig,
  plusoundIdenityApi,
  plusoundFrontEndServices
}




// © plusound 2020
