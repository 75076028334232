import React from 'react';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader';
import './assets/css/plusound-theme-normal.css';
import './assets/css/plusound.css';
import './assets/css/preloader.css';
import './assets/css/plusound-custom.css';
import 'flag-icon-css/css/flag-icon.min.css';
import App from './containers/App';
import {Provider} from 'react-redux';
import {startFireBase} from './helpers/services/firebase';
import store from './helpers/store';
import * as serviceWorker from './serviceWorker';


//initialize firebase
startFireBase();

//load font
WebFont.load({
  google:{
    families:['Futura' , 'Droid Serif'],
  }
})


ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();









// © plusound 2020
