import React, {PureComponent} from 'react';
//import redux tools
import {connect} from 'react-redux';
import {setPageInfo, bootServer} from '../../actions';

//import plusound components
import {
  StandardNav,
  StandardFooter
} from '../../components';

class LandingWrapper extends PureComponent{
  componentDidMount(){
    if(window.LoadThemes){
      window.LoadThemes();
    }

    this.props.setPageInfo({
      title:this.props.pageTitle
    })

    this.props.bootServer();
  }

  render(){
    window.Intercom('update');
    return(
      <>
        <main className="cs-page-wrapper min-vh-75">
          <StandardNav
          hasTopNav={false}
          classList="cs-header navbar navbar-expand-lg navbar-light bg-light navbar-sticky"
          items={[
            {
              name:"Projects",
              link:"/#projects"
            },
            {
              name:"Careers",
              link:"/careers"
            },
            {
              name:"About us",
              link:"/#about-us"
            },
            {
              name:"Win",
              link:"/win"
            }
          ]}
          />
            {this.props.children}
          <StandardFooter/>
        </main>
      </>
    );
  }
}


let mapStateToProps = state => (
  {
    data:state
  }
)

let mapDispatchToProps = {
  setPageInfo,
  bootServer
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingWrapper);
