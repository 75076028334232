import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import ModalVideo from 'react-modal-video'
import Typed from 'react-typed';
/**** import images ****/
import figure from '../assets/images/listening.jpeg';
import particle1 from '../assets/images/particle1.png';
import particle2 from '../assets/images/particle2.png';
import musicians from '../assets/images/we-are-musicians.jpeg';
import creators from '../assets/images/we-are-creators.jpeg';
import developers from '../assets/images/we-are-developers.jpeg';
import plusoundlink from '../assets/images/projects/plusoundlink.png';
import plusoundforartists from '../assets/images/projects/plusoundforartists.png';
import youtube from '../assets/images/projects/youtube.png';
import youth from '../assets/images/happy-youth.jpeg';
import mic from '../assets/images/icon-mic.jpg';
import code from '../assets/images/icon-code.svg';
import headphone from '../assets/images/icon-headphone.svg';
// import pforartists from '../assets/images/pforartists.png';
// import fansupport from '../assets/images/fansupport.png';
// import plusoundrelease from '../assets/images/projects/plusoundrelease.png';
// import plusoundstudios from '../assets/images/projects/plusoundstudios.png';
// import podcast from '../assets/images/projects/podcast.png';
// import blankproject from '../assets/images/projects/blank.png';
// import release from '../assets/images/release.png';
/**** A wrapper for landing page ****/
import {
  LandingWrapper
} from './wrappers';

// Slider lib
import TinySlider from "tiny-slider-react";
const settings = {
  lazyload: true,
  nav: true,
  navPosition:"bottom",
  navAsThumbnails:true,
  mouseDrag: true,
  controls:false,
  mode:"carousel",
  autoplay:true,
  autoplayTimeout:10000,
  autoplayButtonOutput:false
};

class HomePage extends PureComponent {

  state = {
    isOpen:false
  }
  render(){
    return(
      <LandingWrapper
      pageTitle="Music through Innovation"
      >
         <section className="position-relative bg-light py-6 overflow-hidden">
           <div className="container pt-5 pb-4 pt-lg-5">
             <div className="row justify-content-center align-items-center">
               <div className="col-lg-5 mt-lg-n7 pb-5 mb-sm-3 mb-lg-0 pb-lg-0 text-center text-lg-left">
                 <h1 className="display-4">We think music differently</h1>
                 <p className="pb-2">We build innovative music solutions through design thinking.</p>
                 <Link className="cs-video-btn cs-video-btn-primary mr-3" to="#" onClick={(e)=> {e.preventDefault(); this.setState({isOpen:true})}}/>
                 <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='TlDBQYsKud4' onClose={() => this.setState({isOpen: false})} />
                 <span className="font-size-sm text-muted">
                 Watch our recent music video
                 </span>
               </div>
               <div className="col-lg-7">
                 <div className="cs-parallax mx-auto" style={{maxWidth: 705}}>
                   <div className="cs-parallax-layer position-relative" data-depth="0.1" style={{transform: 'translate3d(7px, 0.9px, 0px)', transformStyle: 'preserve-3d', backfaceVisibility: 'hidden', position: 'relative', display: 'block', left: 0, top: 0}}><img src={figure} className="rounded box-shadow" alt="Layer" /></div>
                   <div className="cs-parallax-layer" data-depth="0.5" style={{transform: 'translate3d(33.9px, 13.3px, 0px)', transformStyle: 'preserve-3d', backfaceVisibility: 'hidden', position: 'absolute', display: 'block', left: 0, top: 0}}><img src={particle1} alt="Layer" /></div>
                   <div className="cs-parallax-layer" data-depth="0.25" style={{transform: 'translate3d(-2.1px, -0.6px, 0px)', transformStyle: 'preserve-3d', backfaceVisibility: 'hidden', position: 'absolute', display: 'block', left: 0, top: 0}}><img src={particle2} alt="Layer" /></div>
                 </div>
               </div>
             </div>
           </div>
         </section>
         {/* About us*/}
         <section className="py-6">
           <div className="container bg-light rounded box-shadow py-6 px-4 px-md-0">
             <div className="row align-items-center py-3 py-md-0">
               <div className="col-xl-6 col-lg-5 col-md-4 text-center">
                 <h2 className="pb-3 pb-md-0">About  Us</h2>
               </div>
               <div className="col-xl-5 col-lg-6 col-md-7 text-center text-md-left">
                 <p>At Plusound, we make music, we design and develop solutions to common problems among independent artists.</p>
               </div>
             </div>
           </div>
         </section>
         <section className="container overflow-hidden py-6">
          <TinySlider settings={settings} className="cs-carousel-inner grab">
             <div className="mb-7">
               <div className="row justify-content-center align-items-center">
                 <div className="col-md-5 mb-md-0 mb-grid-gutter">
                 <img style={{filter:'grayscale(100%)', opacity:'0.8'}} className="rounded" src={musicians} alt="Musicians"/>
                 </div>
                 <div className="col-lg-6 offset-lg-1 col-md-7">
                   <h2 className="display-4 mb-2 inline">We are Musicians</h2>
                   <p className="mb-4 pb-2 text-muted">We make music and help independent artists get their music to the world.</p>
                 </div>
               </div>
             </div>
             <div className="mb-7">
               <div className="row align-items-center">
                 <div className="col-md-5 mb-md-0 mb-grid-gutter opacity-75"><img style={{filter:'grayscale(100%)'}} className="rounded" src={creators} alt="Creators"/>
                 </div>
                 <div className="col-lg-6 offset-lg-1 col-md-7">
                   <h2 className="display-4 mb-2 inline">We are Creators</h2>
                   <p className="mb-4 pb-2 text-muted">We don't reinvent the wheel. We design uniqueness.</p>
                 </div>
               </div>
             </div>
             <div className="mb-7">
               <div className="row align-items-center">
                 <div className="col-md-5 mb-md-0 mb-grid-gutter opacity-75"><img className="rounded" style={{filter:'grayscale(100%)'}} src={developers} alt="Developers"/>
                 </div>
                 <div className="col-lg-6 offset-lg-1 col-md-7">
                   <h2 className="display-4 mb-2 inline">We are Developers</h2>
                   <p className="mb-4 pb-2 text-muted">We build music products with cutting edge technology.</p>
                 </div>
               </div>
             </div>
           </TinySlider>
         </section>
         <section className="bg-secondary overflow-hidden py-6" id="projects">
         <div className="container">
            <h2 className="text-center pt-4 pt-md-0">From Plusound</h2>
            <p className="text-center text-muted pb-4">Find out about our products</p>
            <div className="mb-3">
              <div className="row" data-columns={3}>
                <div className="col-lg-4 col-sm-6 mb-grid-gutter">
                  <a className="card border-0 rounded box-shadow" href="https://artists.plusound.com" target="blank"><img className="card-img-top" src={plusoundforartists} alt="Plusound for artists" />
                      <div className="card-body text-center">
                        <h3 className="h5 nav-heading mb-2">Plusound for Artists</h3>
                        <p className="font-size-sm text-muted mb-2">A Platform for Independent artists</p>
                      </div>
                  </a>
                </div>
                <div className="col-lg-4 col-sm-6 mb-grid-gutter">
                  <a className="card border-0 rounded box-shadow" href="https://www.plusound.link" target="blank"><img className="card-img-top" src={plusoundlink} alt="Plusound link" />
                    <div className="card-body text-center">
                      <h3 className="h5 nav-heading mb-2">Plusound Link</h3>
                      <p className="font-size-sm text-muted mb-2">Create fan link for free</p>
                    </div>
                  </a>
                </div>
                <div className="col-lg-4 col-sm-6 mb-grid-gutter">
                  <a className="card border-0 rounded box-shadow" href="https://www.youtube.com/channel/UCAAew4Qic6rSfNWmvhGoG3A" target="blank"><img className="card-img-top" src={youtube} alt="Youtube channel" />
                    <div className="card-body text-center">
                      <h3 className="h5 nav-heading mb-2">Youtube <span className="text-primary"></span></h3>
                      <p className="font-size-sm text-muted mb-2">Watch our Youtube channel</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          </section>
           <section className="position-relative bg-secondary overflow-hidden pb-6" id="our-mission">
              <div className="container rounded-lg">
               <div className="row justify-content-center align-items-center no-gutters">
                 <div className="col-md-6 py-7 text-center">
                   <img src={youth} className="rounded" alt="music"/>
                 </div>
                 <div className="col-md-6 px-3 pr-xl-0 pl-lg-5 pl-xl-6 py-2 py-lg-1">
                   <div className="ml-sm-4 py-5 my-sm-0 py-md-6 py-lg-7" style={{maxWidth: 520}}>
                     <h2 className="mb-5">
                       How We <span className="text-primary"> Work</span>
                     </h2>
                     <div className="media mb-grid-gutter">
                       <div className="media-body ml-2">
                         <h3 className="h5 mb-1">We Find Problem</h3>
                         <p className="font-size-sm mb-0 text-muted"> We engage with independent artists to identify common problem.</p>
                       </div>
                     </div>
                     <div className="media mb-grid-gutter">
                       <div className="media-body ml-2">
                         <h3 className="h5 mb-1">We Design</h3>
                         <p className="font-size-sm mb-0 text-muted">We use design thinking to innovate new solution.</p>
                       </div>
                     </div>
                     <div className="media">
                       <div className="media-body ml-2">
                         <h3 className="h5 mb-1">We Solve</h3>
                         <p className="font-size-sm mb-0 text-muted">We develop a solution to solve <Typed strings={['the problem. ']} typeSpeed={20}/></p>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </section>
           <section className="py-6">
             <div className="container rounded box-shadow py-6 py-sm-7">
               <div className="row justify-content-center align-items-center">
                 <div className="col-lg-5 col-md-7 col-sm-9 pb-3 mb-4 pb-lg-0 mb-lg-0">
                   <div className="d-flex align-items-center justify-content-between">
                     <div className="mr-2 opacity-50"><img src={mic} width={80} alt="Device" />
                     </div>
                     <div className="mr-3 opacity-50"><img src={code} width={60} alt="Device" />
                     </div>
                     <div className="mr-3 opacity-50"><img src={headphone} width={60} alt="Device" />
                     </div>
                   </div>
                 </div>
                 <div className="col-xl-5 col-lg-6 offset-lg-1 text-center text-lg-left">
                   <h2><span className="text-body opacity-25">Our</span> Mission</h2>
                   <p className="mb-0">To build solutions that creates a sustainable and successful environment for independent artists.</p>
                 </div>
               </div>
             </div>
           </section>
      </LandingWrapper>
    );
  }
}
export default HomePage;
// © plusound 2021
