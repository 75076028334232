import React, {Component} from 'react';
import {
  LandingWrapper
} from './wrappers';
import {connect} from 'react-redux';
import {sendMessage} from '../actions'
import mailSent from '../assets/images/mail_sent.svg';
// import contact from '../assets/images/contact.png';
class Contact extends Component{
  render(){
    let {
      sending,
      sent,
      error
    } = this.props.data.contact;
    return(
      <LandingWrapper
      pageTitle="Contact us"
      >
        <section className="py-6">
          <div className="container-fluid bg-overlay-content py-6">
            <div className="row justify-content-center">
              <div className="col-lg-10">
                <h1 className="">Let's Talk <span role="img" aria-label="emoji">😊</span></h1>
                <p className="">Get in touch with us by completing the form below</p>
              </div>
            </div>
          </div>
        </section>
        <section className="container-fluid bg-overlay-content">
          <div className="row justify-content-center align-items-start">
            <div className="col-lg-6 col-md-7 offset-lg-1 pb-2 mb-5">
              <div className="card border-0 box-shadow-lg">
              {
                !sent?
                <form className="card-body needs-validation p-5" noValidate onSubmit={this.handleSendMessage}>
                  {
                    error?
                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                      <span className="font-weight-medium">{error}</span>
                      <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    :
                    null
                  }
                  <div className="form-group">
                    <label className="form-label" htmlFor="cont-fn">Full name<sup className="text-danger ml-1">*</sup></label>
                    <input className="form-control" type="text" id="cont-fn" placeholder="John Doe" required ref={(input)=>this.full_name = input} />
                    <div className="invalid-feedback">Please enter your full name!</div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="cont-email">Email address<sup className="text-danger ml-1">*</sup></label>
                    <input className="form-control" type="email" id="cont-email" placeholder="j.doe@example.com" required ref={(input)=>this.email = input} />
                    <div className="invalid-feedback">Please enter a valid email address!</div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="cont-phone">Phone number</label>
                    <input className="form-control bg-image-0" type="text" id="cont-phone" defaultValue="+" data-format="custom" data-delimiter="-" data-blocks="15" placeholder="+27 000000000" ref={(input)=>this.phone = input} />
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="cont-company">Company</label>
                    <input className="form-control bg-image-0" type="text" id="cont-company" placeholder="Your company" ref={(input)=>this.company = input} />
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="cont-subject">Subject<sup className="text-danger ml-1">*</sup></label>
                    <input className="form-control" type="text" id="cont-subject" placeholder="Title of your message" required ref={(input)=>this.subject = input} />
                    <div className="invalid-feedback">Please enter a subject!</div>
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="cont-message">Message<sup className="text-danger ml-1">*</sup></label>
                    <textarea className="form-control" id="cont-message" rows={5} placeholder="Write your message here" required defaultValue={""} ref={(input)=>this.message = input} />
                    <div className="invalid-feedback">Please write a message!</div>
                  </div>
                  <div className="text-center pt-2">
                    <button className="btn btn-primary" type="submit" disabled={sending||false}>
                    {
                      sending?
                      <>
                        <span className="spinner-grow spinner-grow-sm mr-2" role="status" aria-hidden="true" />
                        Sending...
                      </>
                      :
                      "Send Message"
                    }
                    </button>
                  </div>
                </form>
                :
                <div className="card-body pt-7 pb-7 p-5 text-center">
                  <div className="pt-7 pb-7">
                    <img src={mailSent} alt="sent" width={150}/>
                    <p>Thank you, we received your message. A team member will be in touch shortly.</p>
                  </div>
                </div>
              }
              </div>
            </div>
            <div className="col-lg-4 col-md-4 offset-md-1 pt-3 pt-lg-5">
              <h2 className="h4 pb-3">Contact details</h2>
              <h3 className="h6 pb-2">Cape Town - South Africa</h3>
              <ul className="list-unstyled font-size-sm pb-3">
                <li className="d-flex align-items-top mb-3">
                  <i className="fe-map-pin font-size-xl text-muted mt-1 mr-2 pr-1" />
                  <div>Cape Town, ZA </div>
                </li>
                <li className="d-flex align-items-center mb-3">
                  <i className="fe-mail font-size-xl text-muted mr-2 pr-1" />
                  <div>ayodeji@plusound.com</div>
                </li>
              </ul>
              <h3 className="h6 pb-2">Lagos - Nigeria</h3>
              <ul className="list-unstyled font-size-sm">
                <li className="d-flex align-items-top mb-3">
                  <i className="fe-map-pin font-size-xl text-muted mt-1 mr-2 pr-1" />
                  <div>House 11 Akinyemi Omoyemi St,<br />Oral Estate,<br />Lekki, Lagos, NG<br /></div>
                </li>
                <li className="d-flex align-items-center mb-3">
                  <i className="fe-mail font-size-xl text-muted mr-2 pr-1" />
                  <div>ayodeji@plusound.com</div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </LandingWrapper>
    );
  }
  handleSendMessage=(e)=>{
    e.preventDefault();
    let {
      full_name,
      email,
      phone,
      company,
      subject,
      message
    } = this;

    let required_form_data = [
      full_name,
      email,
      subject,
      message
    ]
    if(validateInput(required_form_data)){
      let data = {
        full_name:full_name['value'],
        company:company['value'],
        email:email['value'],
        phone:phone['value'],
        subject:subject['value'],
        message:message['value']
      }
      let {sendMessage} = this.props;
      sendMessage(data, {category:'contact'})
    }
    /** validator **/
    function validateInput(inputArray){
      let err = []

      inputArray.map((input)=>{
        return !input.validity.valid?
        err.push(input)
        :
        null
      })

      if(err.length){
        return false
      }else{
        return true
      }
    }
  }
}
let mapStateToProps = state => ({
  data:state
})
let mapDispatchToProps = {
  sendMessage
}
export default connect(mapStateToProps, mapDispatchToProps)(Contact)
// © plusound 2021
