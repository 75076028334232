import {createStore , applyMiddleware , compose} from 'redux';
import thunk from 'redux-thunk';
import reducers from '../reducers';

const thunkMiddleware = applyMiddleware(thunk);
const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__ ? window.window.__REDUX_DEVTOOLS_EXTENSION__(): f =>f
const composedEnhancer = compose(thunkMiddleware,devToolsExtension);
export default createStore(reducers, composedEnhancer);

// © plusound 2020
