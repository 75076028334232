import React, {PureComponent} from 'react';
import ApiMessenger from '../helpers/services/api';




//import plusound components
import {
  LandingWrapper
} from './wrappers';


class EmailConfirmation extends PureComponent {

  componentDidMount(){
    let {token} = this.props.match.params;
    if(token){
      new ApiMessenger().confirmEmail({token})
      .then((response)=>{
        if(response){
          let {message} = response.data;
          if(message){
            this.setState({
              loading:false,
              status:"Email verified",
              icon:<i className="fe-check-circle h1 text-success"></i>,
              message
            })
          }
        }
      })
      .catch((error)=>{
        if(!error.response){
          let {message} = error;
          return this.setState({
            loading:false,
            status:message,
            icon:<i className="fe-alert-circle h1 text-danger"></i>,
            message
          })
        }
        this.setState({
          loading:false,
          status:"Email verification failed",
          icon:<i className="fe-alert-circle h1 text-danger"></i>,
          message:error.response.data.error
        })
      })
    }else{
      this.props.history.push('/user/join');
    }
  }

  state = {
    loading:true,
    status:'',
    icon:'',
    message:''
  }

  render(){
    let {
      loading,
      status,
      icon,
      message
    } = this.state;
    return(
      <LandingWrapper
      pageTitle="Email confirmation"
      >
        <div className="container py-5 py-sm-6 py-md-7">
          <div className="row justify-content-center text-center pt-7">
            <div className="col-lg-7 col-md-9 col-sm-11">
              {
                loading?
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
                :
                icon
              }
              <h1 className="h2 pb-3">
              {
                loading?
                'Verifying email':
                status
              }
              </h1>
              <p className="font-size-sm">
              {
                loading?
                'Your email is being verified. It usually takes few seconds':
                message
              }
              </p>
            </div>
          </div>
        </div>
      </LandingWrapper>
    );
  }
}

export default EmailConfirmation;
// © plusound 2020
