import React, {PureComponent} from 'react';

/*** Redux ***/
import {connect} from 'react-redux';
import {
  requestResetCode,
  confirmResetCode,
  resetPassword
} from '../actions';

/*** Wrapper ***/
import {
LandingWrapper
} from './wrappers';


class PasswordReset extends PureComponent {
  state={
    errors:{},
    code:''
  }
  render(){
    let {errors} = this.state;
    let {
      error, 
      email,
      stage,
      loading
    } = this.props.data.passwordReset;
    return(
      <LandingWrapper
      pageTitle="Reset Password"
      >
        <div className="container py-5 py-sm-6 py-md-7">
          <div className="row justify-content-center pt-4">
            <div className="col-lg-7 col-md-9 col-sm-11">
              <h1 className="h2 pb-3">Forgot your password?</h1>
              <p className="font-size-sm">
                Change your password in three easy steps. This helps to keep your new
                password secure.
              </p>
              <ul className="list-unstyled font-size-sm pb-1 mb-4">
                <li>
                  <span className="text-primary font-weight-semibold mr-1 del">1.</span>
                    {stage>1? <del>Fill in your email address below.</del>:'Fill in your email address below.'}
                  {stage>1 ? <i className="fe-check text-success"></i> : null}
                </li>
                <li>
                  <span className="text-primary font-weight-semibold mr-1">2.</span>
                    {stage>2? <del>We'll email you a temporary code.</del>:'We\'ll email you a temporary code.'}
                  {stage>2 ? <i className="fe-check text-success"></i> : null}
                </li>
                <li>
                  <span className="text-primary font-weight-semibold mr-1">3.</span>
                    {stage>3? <del>Use the code to change your password on our secure website.</del>:'Use the code to change your password on our secure website.'}
                  {stage>3 ? <i className="fe-check text-success"></i> : null}
                </li>
              </ul>
              {
                (stage === 1)?
              <div className="bg-secondary rounded-lg px-3 py-4 p-sm-4">
                <form className="needs-validation p-2" noValidate onSubmit={this.handleRequestResetCode}>
                {
                  (errors && errors.email) || error ?
                  <label className="text-danger form-label">{errors.email || error}</label>
                  :
                  <label className="form-label" htmlFor="recovery-email">
                    Enter your email address
                  </label>
                }
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="email"
                      required
                      id="recovery-email"
                      ref={(input)=>this.recovery_email = input}
                    />
                  </div>
                  {
                    loading?
                    <button type="button" className="btn btn-primary">
                      <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                      Sending...
                    </button>
                    :
                    <button className="btn btn-primary" type="submit">
                      Send code
                    </button>
                  }
                </form>
             </div>
                :

                (stage === 2)?
              <div className="bg-secondary rounded-lg px-3 py-4 p-sm-4">
                <form className="p-2" noValidate onSubmit={this.handleConfirmResetCode}>
                  <div className="form-group">
                  {
                    (errors && errors.code) || error ?
                    <label className="text-danger form-label">{errors.code || error}</label>
                    :
                    <label className="form-label" htmlFor="reset-code">
                      {`Enter the 6 digit code sent to ${email}`}
                    </label>
                  }
                    <input
                      className="form-control"
                      required
                      id="reset-code"
                      data-format="code"
                      placeholder="0-0-0-0-0-0"
                      ref={(input)=> this.reset_code = input}
                    />
                  </div>
                  {
                    loading?
                    <button type="button" className="btn btn-primary">
                      <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                      Verifying...
                    </button>
                    :
                    <button className="btn btn-primary" type="submit">
                      Verify
                    </button>
                  }
                </form>
              </div>
                :

                (stage ===3)?
              <div className="bg-secondary rounded-lg px-3 py-4 p-sm-4">
                <form className="needs-validation p-2" noValidate onSubmit={this.handleResetPassword}>
                {
                  (errors && errors.new_password) || error?
                  <label className="text-danger form-label">{errors.new_password || error}</label>
                  :
                  <label className="form-label" htmlFor="new-password">
                    Enter new password
                  </label>
                }
                  <div className="input-group-overlay cs-password-toggle form-group">
                    <div className="input-group-prepend-overlay">
                      <span className="input-group-text">
                        <i className="fe-lock" />
                      </span>
                    </div>
                    <input
                      className="form-control prepended-form-control"
                      type="password"
                      id="new-password"
                      placeholder="New password"
                      required
                      ref={(input)=>this.new_password = input}
                    />
                    <label className="cs-password-toggle-btn">
                      <input className="custom-control-input" type="checkbox" />
                      <i className="fe-eye cs-password-toggle-indicator" />
                      <span className="sr-only">Show password</span>
                    </label>
                  </div>
                  {
                    loading?
                    <button type="button" className="btn btn-primary">
                      <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                      Setting...
                    </button>
                    :
                    <button className="btn btn-primary" type="submit">
                      Set password
                    </button>
                  }
                </form>
              </div>
                :

                <div className="bg-secondary rounded-lg px-3 py-4 p-sm-4">
                  <form className="p-2" noValidate>
                  {
                    (errors && errors.email) || error ?
                    <label className="text-danger form-label">{errors.email || error}</label>
                    :
                    <label className="form-label" htmlFor="recovery-email">
                      All step complete. Sign in with your new password
                    </label>
                  }
                    <div className="form-group">
                      <input
                        className="form-control"
                        type="email"
                        required
                        id="recovery-email"
                        disabled={true}
                      />
                    </div>
                      <a className="btn btn-primary" href="/user/sign-in">
                        Sign in
                      </a>
                  </form>
               </div>
              }
            </div>
          </div>
        </div>
      </LandingWrapper>
    );
  }

  handleRequestResetCode =(e)=> {
    e.preventDefault();
    let {requestResetCode} = this.props;
    let {value} = this.recovery_email;
    if(this.emailPatternValidator(value)){
      this.setState({
        errors:{
          ...this.state.errors,
          email:''
        }
      })
      return requestResetCode(value);
    }
    this.setState({
      errors:{
        ...this.state.errors,
        email:'Enter valid recovery email'
      }
    })
  }

  handleConfirmResetCode =(e)=> {
    e.preventDefault();
    let {confirmResetCode} = this.props;
    let {value} = this.reset_code;
    let {token} = this.props.data.passwordReset;
    let {sanitizeCode} = this;
    if(sanitizeCode(value) && token){
      let code = sanitizeCode(value);
      let data = {
        code,
        token
      }
      this.setState({
        errors:{
          ...this.state.errors,
          code:''
        }
      })
      return confirmResetCode(data);
    }
    this.setState({
      errors:{
        ...this.state.errors,
        code:'Enter a valid 6 digit code'
      }
    })
  }

  handleResetPassword =(e)=> {
    e.preventDefault();
    let {resetPassword} = this.props;
    let {value} = this.new_password;
    let {token} = this.props.data.passwordReset;
    if(this.passwordPatternValidator(value)){
      let data = {
        new_password:value,
        token
      }
      this.setState({
        errors:{
          ...this.state.errors,
          new_password:''
        }
      })
      return resetPassword(data);
    }
    this.setState({
      errors:{
        ...this.state.errors,
        new_password:'Password must be minimim of 8 characters 1 number and a special character'
      }
    })
  }

/*** Code formatter ***/
  sanitizeCode =(code)=> {
    code = code.replace(/-/g, "");
    if(code.length<6){
      return false
    }
    return code;
  }

/*** Emaill pattern val. ***/
  emailPatternValidator=(email)=>{
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

/*** Password pattern val. ***/
  passwordPatternValidator=(password)=>{
    // eslint-disable-next-line
    var re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
    return re.test(String(password));
  }

}


let mapStateToProps = state => (
  {
    data:state
  }
);

let mapDispatchToProps = {
  requestResetCode,
  confirmResetCode,
  resetPassword
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
// © plusound 2020
