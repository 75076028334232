import React from 'react';

//import images
import plusoundicon from '../../assets/images/plusound-icon.png';


let TopBar = (props) => {

  return(
    <div className="topbar topbar-dark bg-secondary">

      <div className="container d-md-flex align-items-center px-0 px-xl-3">
        <div className="d-none d-md-block text-nowrap mr-3">
          <i className="fe-messa font-size-base text-muted mr-1" />
          <span className="text-muted mr-2"></span>
          <a className="topbar-link mr-1" href="tel:9107848015">

          </a>
        </div>

        <div className="d-flex text-md-right ml-md-auto">
          <div className="dropdown ml-auto ml-md-0 mr-3">

            <a
              className="topbar-link dropdown-toggle text-dark"
              href="/#"
              data-toggle="dropdown"
            >
            <span className="flag-icon flag-icon-ng flag-icon-squared mr-2"></span>
              Nigeria
            </a>


            <div className="dropdown-menu dropdown-menu-right">

              <a className="dropdown-item" href="/#">
              <span className="flag-icon flag-icon-ng flag-icon-squared mr-2"></span>
                Nigeria
              </a>

              <a className="dropdown-item" href="/#">
              <span className="flag-icon flag-icon-za flag-icon-squared mr-2"></span>
                South Africa
              </a>

              <a className="dropdown-item" href="/#">
                <img
                  className="mt-n1 mr-2"
                  width={20}
                  src={plusoundicon}
                  alt="Italiano"
                />
                World
              </a>

            </div>
          </div>
        </div>
      </div>

    </div>

  );
}

export default TopBar;
// © plusound 2020
