import actionTypes from '../constants';


let initialState = {
  loggingIn:false,
  loggedIn:false,
  newUser:false,
  token:'',
  error:''
}


export function authentication(state = initialState, action){
  let o = state;

  switch (action.type) {
    case actionTypes.SIGN_IN_REQUEST:
      return {
        loggingIn:true,
        loggedIn:false,
      }
    case actionTypes.SIGN_IN_SUCCESS:
      return {
        loggingIn:false,
        loggedIn:true,
        token:action.token
      }
    case actionTypes.SIGN_IN_FAILURE:
      return {
        loggingIn:false,
        loggedIn:false,
        error:action.error
      }
    default:
      return o;
  }
}



// © plusound 2020
