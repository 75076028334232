import actionTypes from '../constants';
import ApiMessenger from '../helpers/services/api';
import Notification from './notification';
let Notifier = new Notification();
//import history from '../helpers/history';


//create instance of ApiMessenger
let api = new ApiMessenger();



export function isUsernameAvailable(username){
  return dispatch=>{
    if(!username){
      return dispatch(failure({username:{available:"", error:'enter a username'}}))
    }
    let o = {
      tid:"01",
      data:{
        key:"username",
        value:username
      }
    }

    dispatch(request(o));
    api.getCount(o).then((result)=>{
      let {count} = result.data;
      if(count>0){
       return dispatch(success({username:{available:false}}))
      }
       dispatch(success({username:{available:true}}))
    }).catch((error)=>{
      if(!error.response){
        let report = {title:"Error", report:"Network Error"}
        Notifier.reportFailure(report)
        dispatch(failure({username:{available:"", error:error.message}}));
      }else{
        dispatch(failure({username:{available:"", error:error.response.data.error}}));
      }
    })
  }
  function request(request){return{type:actionTypes.REALTIME_DATA_REQUEST, request}}
  function success(data){return{type:actionTypes.REALTIME_DATA_RECEIVED, data}}
  function failure(error){return{type:actionTypes.REALTIME_DATA_FAILURE, error}}
}












export function isEmailAvailable(email){
  return dispatch=>{
    if(!email){
      return dispatch(failure({email:{available:"", message:'enter an email'}}))
    }
    let o = {
      tid:"01",
      data:{
        key:"email",
        value:email
      }
    }

    dispatch(request(o));
    api.getCount(o).then((result)=>{
      let {count} = result.data;
      if(count>0){
       return dispatch(success({email:{available:false}}))
      }
       dispatch(success({email:{available:true}}))
    }).catch((error)=>{
      if(!error.response){
        let report = {title:"Error", report:"Network Error"}
        Notifier.reportFailure(report)
        dispatch(failure({email:{available:"", message:error.message}}));
      }else{
        dispatch(failure({email:{available:"", message:error.response.data.error}}));
      }
    })
  }
  function request(request){return{type:actionTypes.REALTIME_DATA_REQUEST, request}}
  function success(data){return{type:actionTypes.REALTIME_DATA_RECEIVED, data}}
  function failure(error){return{type:actionTypes.REALTIME_DATA_FAILURE, error}}
}



// © plusound 2020
