import {combineReducers} from 'redux';



import pageInformation from './page_information_reducer';
import {authentication} from './authentication';
import {newUser} from './user_reducer';
import {identity} from './identity';
import realTimeData from './realtime_data';
import passwordReset from './password_reset';
import contact from './contact';




export default combineReducers({
  pageInformation,
  authentication,
  newUser,
  identity,
  realTimeData,
  passwordReset,
  contact
})


// © plusound 2020
