import React from 'react';
import {PropTypes} from 'prop-types';
import plusoundark from '../../assets/images/plusoundark.png';
import plusoundicon from '../../assets/images/plusound-icon.png';
import TopBar from './topbar';
import {SigninSignupModal} from '../modals_';
let StandardNav = (props) => {
  //declare all props
  let {
    hasTopNav,
    classList,
    items,
//  identity,
//  signout
  } = props;
  return(
    <>
    <SigninSignupModal/>
    <header className="cs-header">
    {
      hasTopNav? <TopBar/> : null
    }
    <div className={`${hasTopNav?'navbar-box-shadow':'navbar-floating'} navbar navbar-expand-lg navbar-sticky ${classList?classList:""}`}>
      <div className="container px-0 px-xl-3">
        <button className="navbar-toggler ml-n2 mr-2" type="button" data-toggle="offcanvas" data-offcanvas-id="primaryMenu">
          <span className="navbar-toggler-icon"></span>
        </button>
        <a className="navbar-brand order-lg-1 mx-auto ml-lg-0 pr-lg-2 mr-lg-4" href="/">
          <img className="navbar-floating-logo d-none d-lg-block" width="153" src={plusoundark} alt="Plusound"/>
          <img className="navbar-stuck-logo" width="153" src={plusoundark} alt="Plusound"/>
          <img className="d-lg-none" width="58" src={plusoundicon} alt="Plusound"/>
        </a>
        <div className="d-flex align-items-center order-lg-3 ml-lg-auto">
          <a className="nav-link-style font-size-sm text-nowrap" href="/contact">
            <i className="fe-users font-size-xl mr-2"></i>Connect
          </a>
          <a className="btn btn-primary ml-grid-gutter d-none d-lg-inline-block" href="/contact">Partner with us</a>
        </div>

        <div className="cs-offcanvas-collapse order-lg-2" id="primaryMenu">
          <div className="cs-offcanvas-cap navbar-box-shadow">
            <h5 className="mt-1 mb-0">Plusound</h5>
            <button className="close lead" type="button" data-toggle="offcanvas" data-offcanvas-id="primaryMenu"><span aria-hidden="true">&times;</span></button>
          </div>
          <div className="cs-offcanvas-body">

          <ul className="navbar-nav">
          {
            items?
            items.map((item, i)=>
            <li className={`nav-item ${item.dropdown?'dropdown':""}`} key={i}>
              <a
                className= {`nav-link ${item.dropdown?'dropdown-toggle':""}`}
                href={item.link}
                data-toggle={item.dropdown?'dropdown':''}
              >
                {item.name}
              </a>
              {
                item.dropdown?
                <ul className="dropdown-menu">
                {
                  item.dropdown.items?
                  item.dropdown.items.map((dropdown_item, i)=>
                  <li key={i}>
                    <a className="dropdown-item" href={dropdown_item.link}>
                    {
                      item.dropdown.style ==="icon"?
                      <div className="d-flex align-items-center">
                        <div className="font-size-xl text-muted">
                          <i className={dropdown_item.icon} />
                        </div>
                        <div className="pl-3">
                          <span className="d-block text-heading">
                            {dropdown_item.text}
                          </span>
                          <small className="d-block text-muted">
                            {dropdown_item.desc}
                          </small>
                        </div>
                      </div>
                      :
                      dropdown_item.text
                    }
                    </a>
                    {
                      (i < item.dropdown.items.length-1)?
                      <p className="dropdown-divider"/>:
                      null
                    }
                  </li>
                )
                :
                null
                }
                </ul>
                :
                null
              }
            </li>
           )
           :
           null
          }
          </ul>

          </div>
        </div>
      </div>
    </div>
    </header>
    </>
  );
}

StandardNav.propTypes = {
  authenticated:PropTypes.bool,
  identity:PropTypes.object,
  hasTopNav:PropTypes.bool,
  classList:PropTypes.string,
  items:PropTypes.array,
  signout:PropTypes.func
}
export default StandardNav;
//@plusound.com 2021
