import {plusoundIdenityApi, plusoundFrontEndServices} from '../config';

export default class ApiMessenger {

  async join(user){
    return await plusoundIdenityApi.post('/users/join', user);
  }


  async auth(credentials){
    return await plusoundIdenityApi.post('/users/auth', credentials);
  }


  async getIdentity(token){
    return await plusoundIdenityApi.post('/users/identity',{},{headers:{'x-access-token':token}});
  }


  async getCount(data){
    return await plusoundIdenityApi.post('/graph/count',data);
  }

  async confirmEmail(token){
    return await plusoundIdenityApi.post('/users/email-confirmation', token);
  }


  async requestResetCode(request){
    return await plusoundIdenityApi.post('/users/request-reset-code', request);
  }

  async confirmResetCode(data){
    return await plusoundIdenityApi.post('/users/confirm-reset-code', {code:data.code}, {headers:{'x-access-token':data.token}});
  }

  async resetPassword(data){
    return await plusoundIdenityApi.post('/users/reset-password', {new_password:data.new_password}, {headers:{'x-access-token':data.token}});
  }

  async contact(data){
    return await plusoundFrontEndServices.post('/contact', data)
  }

  async careerApplication(data){
    return await plusoundFrontEndServices.post('/contact/career', data)
  }

  async bootServer(){
    return await plusoundFrontEndServices.post('/boot')
  }

}






// © plusound 2020
