import React from 'react';
import {BrowserRouter as Router , Switch} from 'react-router-dom';
import {preloader} from '../actions';
import "notiflix-react/dist/notiflix-react-1.4.0.css";
import "react-modal-video/scss/modal-video.scss";
//Import Plusound Components
import {
  HomePage,
  Contact,
  Career,
  Win
} from '.'
//Import middleware
import {Naked} from '../helpers/middleware';

function App() {
  if(window.LoadThemes){
    window.LoadThemes();
  }
  preloader();
  window.Intercom("boot", {
    app_id: "uk40neaj"
  });
  return (
    <Router>
      <div className="cs-page-loading active">
        <div className="cs-page-loading-inner">
          <div className="cs-page-spinner"></div><span>Loading...</span>
        </div>
      </div>
      <Switch>
        <Naked exact path="/" component={HomePage} />
        <Naked path="/contact" component={Contact} />
        <Naked exact path="/careers" component={Career} />
        <Naked path="/careers/:slug" component={Career} />
        <Naked path="/win" component={Win} />
        <Naked component={HomePage} />
      </Switch>
    </Router>
  );
}
export default App;
//@Plusound.com 2021
