import React from 'react';
import {Route,Redirect} from 'react-router-dom';
import Cookie from 'js-cookie';




function observeUser(){
  let token = Cookie.get('token');
  if(token){
    return true
  }else{
    return false
  }
}


const Component = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
      !observeUser()
      ? <Component {...props} />
      : <Redirect to='/welcome' />
  )} />
)

export default Component;



// © plusound 2020
