import actionTypes from '../constants';



let initialState = {
  email:'.....@plusound.com',
  username:'.....',
  joined_on:'../../....',
  error:[]
}



export function identity(state = initialState, action){
  let o = state;

  switch (action.type) {
    case actionTypes.IDENTITY_REQUEST:
      return o;
    case actionTypes.IDENTITY_SUCCESS:
      return action.identity
    case actionTypes.IDENTITY_FAILURE:
      return {
        error:action.error
      }
    case actionTypes.SIGN_IN_FAILURE:
      return {}
    case actionTypes.SIGN_OUT:
      return {}
    default:
      return o;
  }
}



// © plusound 2020
