import React from 'react';
let PageHeader =(props)=> {
  return (
    <section className="d-md-block py-6 position-relative bg-no-repeat bg-position-center">
      <div className="container">
      {
        props.breadCrumb?
        <nav aria-label="breadcrumb">
          <ol className="py-1 my-2 breadcrumb">
          {
            props.breadCrumb.map((item, i)=>
            <React.Fragment key={i}>
            {
              !item.active?
              <li className={`breadcrumb-item ${item.active?'active':''}`} aria-current={item.active?'page':''}>
                <a href={item.link}>{item.title}</a>
              </li>
              :
              <li className="breadcrumb-item active" aria-current={'page'} key={i}>
                {item.title}
              </li>
            }
            </React.Fragment>
          )
          }
          </ol>
        </nav>
        :
        null
      }
      </div>
      <div className="container py-lg-7 py-md-6 py-5">
        <div className="row">
          <div className="col-xl-6 col-md-8 col-sm-10">
            <h1 className="display-4">{props.title}</h1>
            <p className="text-muted">{props.lead}</p>
            {
              props.buttonText?
              <a className="btn btn-primary" href={props.buttonLink} data-scroll>{props.buttonText}</a>
              :
              null
            }
          </div>
          <div className="col-xl-6 col-md-12 col-sm-10 text-right d-md-block opacity-75">
            <img src={props.img} alt="illustration" width={300}/>
          </div>
        </div>
      </div>
    </section>
  );
}
export default PageHeader;
//@Plusound 2021
