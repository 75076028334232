import actionTypes from '../constants';



let newUserInitialState = {
  created:false,
  creating:false,
  user:{},
  errors:''
}


export function newUser(state = newUserInitialState, action){
  let o = state;
  switch (action.type) {
    case actionTypes.CREATE_USER_REQUEST:
      return {
        creating:true,
        user:{}
      }
    case actionTypes.CREATE_USER_SUCCESS:
      return {
        creating:false,
        created:true,
        user:action.newUser
      }
    case actionTypes.CREATE_USER_FAILURE:
      return {
        creating:false,
        created:false,
        user:{},
        errors:action.error
      }
    default:
    return o;
  }
}



// © plusound 2020
