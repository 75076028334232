import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';


//import redux tools
import {join, auth, isUsernameAvailable, isEmailAvailable} from '../../actions';
import {connect} from 'react-redux';



class SigninSignupModal extends PureComponent{

  state = {
    username:'',
    errors:{}
  }


  componentDidMount(){
    this.usernameChecker();
    this.emailChecker();
  }

  componentWillUnmount(){
    clearInterval(this.username_check_timer, this.email_check_timer);
  }



  render(){
    let {errors} = this.state;
    let {newUser, realTimeData, authentication} = this.props.data;
    let {username, email} = realTimeData;
    let {creating} = newUser;
    let {error, loggingIn} = authentication;

    return(
      <div className="modal fade" id="modal-signin" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content border-0">
            <div className="cs-view show" id="modal-signin-view">
              <div className="modal-header border-0 bg-dark px-4">
                <h4 className="modal-title text-light">Sign in</h4>
                <button className="close text-light" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              </div>
              <div className="modal-body px-4">
                <p className={`${!error?'text-muted':'text-danger'} font-size-ms mb-4`}>
                {!error?
                 `Sign in to manage your music.`
                 :
                 error
                }
                </p>
                <form className="needs-validation" ref={(form)=>this.sign_in_form = form} noValidate onSubmit={this.handleAuth}>
                  <div className="input-group-overlay form-group">
                    <div className="input-group-prepend-overlay"><span className="input-group-text"><i className="fe-mail"></i></span></div>
                    <input  className="form-control prepended-form-control" type="text" placeholder="Username" required ref={(input)=>this.sign_in_username = input}/>
                  </div>
                  <div className="input-group-overlay cs-password-toggle form-group">
                    <div className="input-group-prepend-overlay"><span className="input-group-text"><i className="fe-lock"></i></span></div>
                    <input  className="form-control prepended-form-control" type="password" placeholder="Password" required ref={(input)=>this.sign_in_password = input} autoComplete="true"/>
                    <label className="cs-password-toggle-btn">
                      <input  className="custom-control-input" type="checkbox"/><i className="fe-eye cs-password-toggle-indicator"></i><span className="sr-only">Show password</span>
                    </label>
                  </div>
                  <div className="d-flex justify-content-between align-items-center form-group">
                    <div className="custom-control custom-checkbox">
                      <input  className="custom-control-input" type="checkbox" id="keep-signed"/>
                      <label className="custom-control-label" htmlFor="keep-signed">Keep me signed in</label>
                    </div><a className="nav-link-style font-size-ms" href="/reset">Forgot password ?</a>
                  </div>
                  <button  className="btn btn-primary btn-block" type="submit">
                    {loggingIn?
                      <>
                      <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                      Signing in...
                     </>
                     :
                     'Sign in'
                    }
                  </button>
                  <p className="font-size-sm pt-3 mb-0">Don't have an account? <Link to='#' className='font-weight-medium' data-view='#modal-signup-view'>Join</Link></p>
                </form>
              </div>
            </div>
            <div className="cs-view" id="modal-signup-view">
              <div className="modal-header border-0 bg-dark px-4">
                <h4 className="modal-title text-light">Join</h4>
                <button className="close text-light" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              </div>
              <div className="modal-body px-4">
                <p className={`${newUser.errors?'text-danger':'text-muted'} font-size-ms mb-4`}>{newUser.errors?newUser.errors:`Join now to start taking control of your music.`}</p>
                <form className="needs-validation" ref={(form)=>this.sign_up_form = form} noValidate  onSubmit={this.handleJoin}>
                  <div className="form-group">
                    <input  className="form-control" type="email" placeholder="Email" required ref={(input)=>this.sign_up_email = input} onInput={this.emailChecker} />
                    {
                      errors.email?
                      <small className="text-warning">{errors.email}</small>
                      :
                      null
                    }
                    {
                      (email && email.available===true&&!errors.email&&this.sign_up_email&&this.sign_up_email.value)?
                      <small className="text-success">{`email is available`}</small>
                      :
                      (email && email.available===false&&!errors.email&&this.sign_up_email&&this.sign_up_email.value)?
                      <small className="text-danger">{`email is taken`}</small>
                      :
                      null
                    }
                  </div>
                  <div className="form-group">
                    <input  className="form-control" type="text" placeholder="Username" required value={this.state.username} onInput={this.usernameChecker} onChange={this.sanitizeUsername} maxLength="15" minLength="3" ref={(input)=>this.sign_up_username = input}/>
                    {
                      errors.username?
                      <small className="text-warning">{errors.username}</small>
                      :
                      null
                    }
                    {
                      (username && username.available===true&&!errors.username&&this.sign_up_username&&this.sign_up_username.value)?
                      <small className="text-success">{`username is available`}</small>
                      :
                      (username && username.available===false&&!errors.username&&this.sign_up_username&&this.sign_up_username.value)?
                      <small className="text-danger">{`username is taken`}</small>
                      :
                      null
                    }
                  </div>
                  <div className="cs-password-toggle form-group">
                    <input  className="form-control" type="password" placeholder="Password" required ref={(input)=>this.sign_up_password = input} onInput={this.passwordChecker} minLength="8" autoComplete="true"/>
                    <label className="cs-password-toggle-btn">
                      <input  className="custom-control-input" type="checkbox"/><i className="fe-eye cs-password-toggle-indicator"></i><span className="sr-only">Show password</span>
                    </label>
                  </div>
                  {
                    errors.password?
                    <small className="text-warning">{errors.password}</small>
                    :
                    null
                  }
                  <button className="btn btn-primary btn-block" type="submit" disabled={creating}>
                       {creating?
                         <>
                         <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                         Joining...
                        </>
                        :
                        'Join'
                       }
                  </button>
                  <p className="font-size-sm pt-3 mb-0">Already have an account? <Link to='#' className='font-weight-medium' data-view='#modal-signin-view'>Sign in</Link></p>
                </form>
              </div>
            </div>
            <div className="modal-body text-center px-4 pt-2 pb-4">
              <hr/>
              <p className="font-size-sm font-weight-medium text-heading pt-4">
                Or sign in with Instagram
              </p>
              <a className="social-btn sb-instagram sb-lg mx-1 mb-2" href="/">
                <i className="fe-instagram"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }






  handleAuth=(e)=>{
    e.preventDefault();
    let {
      sign_in_username,
      sign_in_password
    } = this;
    let {auth} = this.props;
    let credentials = {
      username:sign_in_username.value,
      password:sign_in_password.value
    }
    if(this.handleAuthValidation()){
      auth(credentials);
    }else{
      this.sign_in_form.classList.add('was-validated');
    }
  }





  handleAuthValidation=()=>{
    let {
      sign_in_username,
      sign_in_password
    } = this;
    let validationFields = [
      sign_in_username,
      sign_in_password
    ]
    let err = [];
    validationFields.map((input)=>
      !input.validity.valid?
      err.push(input):
      null
    )
    if(err.length){
      return false
    }else{
      return true
    }
  }






  handleJoin=(e)=>{
    e.preventDefault();
    let {
      sign_up_email,
      sign_up_username,
      sign_up_password,
    } = this;
    let {join} = this.props;
    let user = {
      email:sign_up_email.value,
      username:sign_up_username.value,
      password:sign_up_password.value
    }
    if(this.handleJoinValidation()){
      join(user);
    }else{
      this.sign_up_form.classList.add('was-validated');
    }
  }




  handleJoinValidation=()=>{
    let {errors} = this.state;
    let {
      sign_up_email,
      sign_up_username,
      sign_up_password
    } = this;

    let validationFields = [
      sign_up_email,
      sign_up_username,
      sign_up_password
    ]
    let err = [];
    validationFields.map((input)=>
      !input.validity.valid?
      err.push(input):
      null
    )

    if(err.length){
      return false;
    }else{
      let {realTimeData} = this.props.data;
      let {username, email} = realTimeData;
      if(username && email && username.available && email.available && !errors.username && !errors.email && !errors.password){
        return true;
      }else{
        return false;
      }
    }
  }


  usernameChecker=()=>{
    clearInterval(this.username_check_timer);
    let {value} = this.sign_up_username;
    let {isUsernameAvailable} = this.props;
    let {usernamePatternValidator} = this;
    this.username_check_timer = setTimeout(()=>{
      if(value&&value.length>=3){
        if(usernamePatternValidator(value)){
          this.setState({
            errors:{
              ...this.state.errors,
              username:''
            }
          })
          isUsernameAvailable(value);
        }else{
          this.setState({
            errors:{
              ...this.state.errors,
              username:'username must contain at least one letter'
            }
          })
        }
      }else if(value&&value.length<3){
        this.setState({
          errors:{
            ...this.state.errors,
            username:'username must be 3 to 30 character long'
          }
        })
      }else{
        return isUsernameAvailable('');
      }
    },500)
  }


  emailChecker=()=>{
    clearInterval(this.email_check_timer);
    let {value} = this.sign_up_email;
    let {emailPatternValidator} = this;
    let {isEmailAvailable} = this.props;
    this.email_check_timer = setTimeout(()=>{
      if(value && emailPatternValidator(value)){
        this.setState({
          errors:{
            ...this.state.errors,
            email:''
          }
        })
        isEmailAvailable(value);
      }else if(value && !emailPatternValidator(value)){
        this.setState({
          errors:{
            ...this.state.errors,
            email:'enter a valid email'
          }
        })
      }else{
        return isEmailAvailable('');
      }
    },500)
  }


  passwordChecker=()=>{
    let {value} = this.sign_up_password;
    let {passwordPatternValidator} = this;
    if(value && passwordPatternValidator(value)){
      this.setState({
        errors:{
          ...this.state.errors,
          password:''
        }
      })
    }else if(value && !passwordPatternValidator(value)){
      this.setState({
        errors:{
          ...this.state.errors,
          password:'Password must be minimim of 8 characters 1 number and a special character'
        }
      })
    }else{
      this.setState({
        errors:{
          ...this.state.errors,
          password:''
        }
      })
    }
  }



  sanitizeUsername=()=>{
    let {sign_up_username, usernameInputValidator} = this;
    let username = sign_up_username.value;
    if(usernameInputValidator(username)){
      this.setState({
        username
      })
    }
  }


  //////pattern validators/////////
  usernameInputValidator=(username)=>{
    //eslint-disable-next-line
    var re = /^[a-zA-Z0-9_.-]*$/;
    return re.test(String(username));
  }
  usernamePatternValidator=(username)=>{
    //eslint-disable-next-line
    var re = /[A-Za-z]/;
    return re.test(String(username));
  }
  passwordPatternValidator=(password)=>{
    // eslint-disable-next-line
    var re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
    return re.test(String(password));
  }
  emailPatternValidator=(email)=>{
    // eslint-disable-next-line
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  //////pattern validators/////////

}
















let mapStateToProps = state => (
  {
    data:state
  }
);


let mapDispatchToProps = {
  join,
  auth,
  isUsernameAvailable,
  isEmailAvailable
};


export default connect(mapStateToProps, mapDispatchToProps)(SigninSignupModal);
// © plusound 2020
