import actionTypes from '../constants';




let initialState = {

  title:'Plusound'
}


export default function pageInformation(state=initialState, action){
  let o = state;
  switch (action.type) {
    case actionTypes.SET_PAGE_INFORMATION:
      o = Object.assign(action.data);
      return o;
    default:
      return state;
  }
}


// © plusound 2020
