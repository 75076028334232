export default {

  SET_PAGE_INFORMATION:'SET_PAGE_INFORMATION',

  CREATE_USER_REQUEST:'CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS:'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE:'CREATE_USER_FAILURE',

  SIGN_IN_REQUEST:'SIGN_IN_REQUEST',
  SIGN_IN_SUCCESS:'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE:'SIGN_IN_FAILURE',

  SIGN_OUT:'SIGN_OUT',

  IDENTITY_REQUEST:'IDENTITY_REQUEST',
  IDENTITY_SUCCESS:'IDENTITY_SUCCESS',
  IDENTITY_FAILURE:'IDENTITY_FAILURE',

  PASSWORD_RESET_CODE_REQUEST:'PASSWORD_RESET_CODE_REQUEST',
  PASSWORD_RESET_CODE_SUCCESS:'PASSWORD_RESET_CODE_SUCCESS',
  PASSWORD_RESET_CODE_FAILURE:'PASSWORD_RESET_CODE_FAILURE',

  CONFIRM_PASSWORD_RESET_CODE_REQUEST:'CONFIRM_PASSWORD_RESET_CODE_REQUEST',
  CONFIRM_PASSWORD_RESET_CODE_SUCCESS:'CONFIRM_PASSWORD_RESET_CODE_SUCCESS',
  CONFIRM_PASSWORD_RESET_CODE_FAILURE:'CONFIRM_PASSWORD_RESET_CODE_FAILURE',

  PASSWORD_RESET_REQUEST:'PASSWORD_RESET_REQUEST',
  PASSWORD_RESET_SUCCESS:'PASSWORD_RESET_SUCCESS',
  PASSWORD_RESET_FAILURE:'PASSWORD_RESET_FAILURE',

  REALTIME_DATA_REQUEST:'REALTIME_DATA_REQUEST',
  REALTIME_DATA_RECEIVED:'REALTIME_DATA_RECEIVED',
  REALTIME_DATA_FAILURE:'REALTIME_DATA_FAILURE',

  SEND_MESSAGE_REQUEST:'SEND_MESSAGE_REQUEST',
  SEND_MESSAGE_SUCCESS:'SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_FAILURE:'SEND_MESSAGE_FAILURE'

}


// © plusound 2020
