import React from 'react';
import Lottie from 'react-lottie';
import TinySlider from "tiny-slider-react";
import Countdown from 'react-countdown';
import cover from '../assets/images/giftaway.png';
import oraimo from '../assets/images/oraimo-giftaway.png';
import giftcards from '../assets/images/giftcard.png';
import merch from '../assets/images/merch.png';
import winmotion from '../assets/lotties/win.json';
// import winnermotion from '../assets/lotties/winner.json';

import {
  LandingWrapper
} from './wrappers';

const settings = {
  lazyload: true,
  loop:true,
  autoplay:true,
  autoplayButton:false,
  autoplayButtonOutput:false,
  nav: true,
  mouseDrag: true,
  controls:false,
  navPosition:"bottom"
};
let defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: winmotion,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
};
// let winnerOption = {
//   ...defaultOptions,
//   animationData:winnermotion
// }

let Win =(props)=> {
  return(
    <LandingWrapper
    pageTitle="Email confirmation"
    >
      <section className="position-relative bg-light overflow-hidden py-6 pb-6" id="our-mission">
         <div className="container">
          <div className="row justify-content-center align-items-center no-gutters">
            <div className="col-md-6 py-7 text-center">
              <img src={cover} className="rounded-sm" alt="music"/>
            </div>
            <div className="col-md-6 px-3 pr-xl-0 pl-lg-5 pl-xl-6 py-2 py-lg-1">
              <div className="ml-sm-4 py-5 my-sm-0 py-md-6 py-lg-7" style={{maxWidth: 520}}>
                <h2 className="mb-5">
                  How To <span className="text-primary"> Win</span>
                </h2>
                <div className="media mb-grid-gutter">
                  <div className="media-body ml-2">
                    <h3 className="h5 mb-1">Take a 30 seconds video</h3>
                    <p className="font-size-sm mb-0 text-muted"> Record a 30 seconds video fo yourself dancing to our latest music release. Party by Drip Lino. Find it on <a href="https://plusound.fanlink.to/party" target="_blank" without rel="noopener noreferrer">plusound.fanlink.to/party.</a></p>
                  </div>
                </div>
                <div className="media mb-grid-gutter">
                  <div className="media-body ml-2">
                    <h3 className="h5 mb-1">Post on Social media</h3>
                    <p className="font-size-sm mb-0 text-muted">Post the video on Tik Tok, Instagram or Facebook. Tag <a href="https://instagram.com/plusoundmusic">@plusoundmusic</a> and hashtag <b>#PLUSOUNDGIFTAWAY.</b></p>
                  </div>
                </div>
                <div className="media">
                  <div className="media-body ml-2">
                    <h3 className="h5 mb-1">3 Lucky Winners <span role="img" aria-label="emoji">⭐⭐⭐</span></h3>
                    <p className="font-size-sm mb-0 text-muted">Submission ends on January 08 2022 where 3 lucky winners will be selected by our judges, based on the post with the most engagement.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="position-relative bg-light overflow-hidden py-6 pb-6">
        <div className="container py-3 px-4">
          <h2 className="">3 Lucky <span className="text-primary">Winners</span></h2>
          <div className="row  justify-content-between align-items-center py-3">
            <div className="col-lg-4">
            <div className="rounded">
              <Lottie
                options={defaultOptions}
                height="100%"
                width="100%"
                className="rounded"
              />
            </div>
            </div>
            <div className="col-lg-4">
            <h3>Items To <span className="text-primary">Win</span></h3>
            <TinySlider settings={settings}>
              <div className="card">
                <img src={oraimo} alt="oraimo gift away" className="rounded grab"/>
              </div>
              <div className="card">
                <img src={merch} alt="plusound merch" className="rounded grab"/>
              </div>
              <div className="card">
                <img src={giftcards} alt="giftcards" className="rounded grab"/>
              </div>
            </TinySlider>
            </div>
          </div>
        </div>
      </section>
      <section className="py-4">
        <div className="container">
          <h2>
            <Countdown date={new Date('January 15, 22 12:00:00 GMT+00:00')} />
          </h2>
          <p>Late Submission Ends In</p>
        </div>
      </section>
    </LandingWrapper>
  );
}
export default Win;
//@Plusound.com 2021
