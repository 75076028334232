import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import "firebase/performance";
import {firebaseConfig} from '../config';



const startFireBase = () => {
  firebase.initializeApp(firebaseConfig)
  firebase.performance();
  firebase.analytics();
}



const createUser = async(credential) => {
  let {sign_in_email, sign_in_password} = credential;
  return await firebase.auth().createUserWithEmailAndPassword(sign_in_email, sign_in_password);
}












export {
  startFireBase,
  createUser
}



// © plusound 2020
