import React from 'react';


//import images
//import plusoundlight from '../../assets/images/plusoundlight.png';
import plusoundark from '../../assets/images/plusoundark.png';



export default class StandardFooter extends React.PureComponent {

  componentDidMount(){
    var chatbox = document.getElementById('fb-customer-chat');
    chatbox.setAttribute("page_id", "101742011650352");
    chatbox.setAttribute("attribution", "biz_inbox");

    window.fbAsyncInit = function() {
      window.FB.init({
        xfbml            : true,
        version          : 'v12.0'
      });
    };

    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  render(){
    return(
      <>
      <footer className="cs-footer bg-light pt-7 9 pt-md-6">
        <div className="container pt-3 pt-md-0">
          <div className="row pb-3">
            <div className="col-md-4 mt-n2 pb-3 pb-md-0 mb-4">
              <a className="d-block mb-3" href="/#" style={{width:'153px'}}>
                <img src={plusoundark} alt="Around"/>
              </a>
              <p className="font-size-sm text-dark opacity-60 pb-2 pb-sm-3">
                Music through Innovation.
              </p>
              <a className="social-btn sb-facebook sb-dark sb-lg mr-2 mb-2" href="https://www.facebook.com/plusoundmusic">
                <i className="fe-facebook"></i>
              </a>
              <a className="social-btn sb-twitter sb-dark sb-lg mr-2 mb-2" href="https://twitter.com/plusoundmusic" target="blank">
                <i className="fe-twitter"></i>
              </a>
              <a className="social-btn sb-instagram sb-dark sb-lg mr-2 mb-2" href="https://instagram.com/plusoundmusic" target="blank">
                <i className="fe-instagram"></i>
              </a>
              <a className="social-btn sb-slack sb-dark sb-lg mr-2 mb-2" href="https://plusound.slack.com" target="blank">
                <i className="fe-slack" />
              </a>
            </div>
            <div className="col-md-2 col-sm-4 ml-auto pb-1 mb-4">
              <div className="cs-widget cs-widget-dark">
                <h4 className="cs-widget-title text-muted">Products</h4>
                <ul>
                  <li><a className="cs-widget-link" href="https://www.instagram.com/plusoundforartists/" target="blank">Plusound for Artists</a></li>
                  <li><a className="cs-widget-link" href="https://plusound.link" target="blank">Plusound Link</a></li>
                  <li><a className="cs-widget-link" href="https://wa.link/wel7pd" target="blank">Plusound Studios</a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 col-sm-4 ml-auto pb-1 mb-4">
              <div className="cs-widget cs-widget-dark">
                <h4 className="cs-widget-title text-muted">Communities</h4>
                <ul>
                  <li><a className="cs-widget-link" href="https://discord.gg/ydbasP5uQQ" target="blank">Discord</a></li>
                  <li><a className="cs-widget-link" href="https://anchor.fm/plusoundforartists" target="blank">Podcast</a></li>
                    <li><a className="cs-widget-link" href="https://www.youtube.com/channel/UCAAew4Qic6rSfNWmvhGoG3A" target="blank">Youtube</a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 col-sm-4 ml-auto pb-1 mb-4">
              <div className="cs-widget cs-widget-dark">
                <h4 className="cs-widget-title text-muted">Company</h4>
                <ul>
                  <li><a className="cs-widget-link" href="/#about-us">About</a></li>
                  <li><a className="cs-widget-link" href="/careers">Careers <span className="badge badge-success">We're Hiring</span></a></li>
                  <li><a className="cs-widget-link" href="https://instagram.com/plusoundmusic" target="blank">Blogs</a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row align-items-center my-3 pt-4">
            <div className="col-md-6 order-md-2 text-md-right mb-3">
              <ul className="list-inline font-size-sm mb-0">
                <li className="list-inline-item"><a className="nav-link-style nav-link-dark" href="mailto:ayodeji@plusound.com">Support</a></li>
                <li className="list-inline-item"><a className="nav-link-style nav-link-dark" href="/contact">Contacts</a></li>
                <li className="list-inline-item"><a className="nav-link-style nav-link-dark" href="/">Privacy Policy</a></li>
              </ul>
            </div>
            <div className="col-md-6 order-md-1 mb-3">
              <p className="font-size-sm mb-0"><span className="mr-1">{`© ${this.getYear()} Plusound. A Godonweb Company.`}</span></p>
            </div>
          </div>
        </div>
      </footer>
      <a className="btn-scroll-top" href="#top" data-scroll><span className="btn-scroll-top-tooltip text-muted font-size-sm mr-2">Top</span><i className="btn-scroll-top-icon fe-arrow-up"></i></a>
      <div id="fb-root"></div>
      <div id="fb-customer-chat" className="fb-customerchat">
      </div>
      </>
    );
  }
  getYear(){
    let y = new Date()
    y = y.getFullYear()
    return y
  }
}
// © plusound 2020
