import Notiflix from "notiflix-react/dist/notiflix-react-1.4.0";


Notiflix.Notify.Init({
  fontFamily:'Futura',
  position:'right-bottom',
  success:{
    background:'#00d97e'
  },
  failure:{
    background:'#E63757'
  },
  warning:{
    background:'#F6C343'
  },
  info:{
    background:'#39afd1'
  }
})



Notiflix.Report.Init({
  className:"text-center float-none ",
  fontFamily:'Futura',
  position:'center',
})



export default class Notification {

  success(message){
    Notiflix.Notify.Success(message);
  }

  warning(message){
    Notiflix.Notify.Warning(message);
  }

  info(message){
    Notiflix.Notify.Info(message);
  }

  failure(message){
    Notiflix.Notify.Failure(message);
  }

  reportSuccess(message){
    Notiflix.Report.Success(message.title, message.report);
  }

  reportFailure(message){
    Notiflix.Report.Failure(message.title, message.report);
  }
}


// © plusound 2020
