import actionTypes from '../constants';



let initialState = {
  stage:1,
  loading:false,
  email:'',
  token:'',
  error:''
}




export default function passwordReset(state=initialState, action){

  switch (action.type) {
    case actionTypes.PASSWORD_RESET_CODE_REQUEST:
      return {...state,...action.request,loading:true,error:''};
    case actionTypes.PASSWORD_RESET_CODE_SUCCESS:
      return {...state,...action.data,stage:2,loading:false,error:''};
    case actionTypes.PASSWORD_RESET_CODE_FAILURE:
      return {...state,error:action.error,stage:1,loading:false};
    case actionTypes.CONFIRM_PASSWORD_RESET_CODE_REQUEST:
      return {...state,...action.request,loading:true,error:''};
    case actionTypes.CONFIRM_PASSWORD_RESET_CODE_SUCCESS:
      return {...state,...action.data,stage:3,loading:false,error:''};
    case actionTypes.CONFIRM_PASSWORD_RESET_CODE_FAILURE:
      return {...state,error:action.error,stage:2,loading:false};
    case actionTypes.PASSWORD_RESET_REQUEST:
      return {...state,...action.request,loading:true,error:''};
    case actionTypes.PASSWORD_RESET_SUCCESS:
      return {...state,...action.data,stage:4,loading:false,error:''};
    case actionTypes.PASSWORD_RESET_FAILURE:
      return {...state,error:action.error,stage:3,loading:false}
    default:
     return state;
  }
}


// © plusound 2020
