import actionTypes from '../constants';
import ApiMessenger from '../helpers/services/api';


//create instance of ApiMessenger
let api = new ApiMessenger();




export function preloader(){
  window.onload = function () {
    var preloader = document.querySelector('.cs-page-loading');
    preloader.classList.remove('active');
    setTimeout(function () {
      preloader.remove();
    }, 2000);
  };
}



export function setPageInfo(data){
  return dispatch => {
    window.document.title = `Plusound | ${data.title}`;
    dispatch(set(data));
  }

  function set(data){return{type:actionTypes.SET_PAGE_INFORMATION, data}}
}



//Boot a free/slow heroku server.
export function bootServer(){
  return dispatch => {
    api.bootServer().then((response)=>{
      //console.log(response.data.ready);
    }).catch((err)=>{
      //console.error(err);
    })
  }
}


// © plusound 2020
