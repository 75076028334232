import React, {Component} from 'react';




//import plusound components
import {
  LandingWrapper
} from './wrappers';




class SignedOut extends Component{
  render(){
    return(
      <LandingWrapper
      pageTitle="Signed out"
      >
        <div className="container py-5 py-sm-6 py-md-7">
          <div className="row justify-content-center text-center pt-7">
            <div className="col-lg-7 col-md-9 col-sm-11">
              <i className="fe-check-circle h1 text-success"></i>
              <h1 className="h2 pb-3"> Signed out</h1>
              <p className="font-size-sm">You have successfully signed out of your account.</p>
            </div>
          </div>
        </div>
      </LandingWrapper>
    );
  }
}

export default SignedOut;
// © plusound 2021
