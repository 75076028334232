import actionTypes from '../constants'

let initialState = {
  sent:false,
  sending:false,
  message:"",
  error:null
}


export default function contact(state = initialState, action){

  switch (action.type) {
    case actionTypes.SEND_MESSAGE_REQUEST:
      return {...state, sending:true, error:null, sent:false}
    case  actionTypes.SEND_MESSAGE_SUCCESS:
      return {...state, sending:false, error:null, message:action.data, sent:true}
    case actionTypes.SEND_MESSAGE_FAILURE:
      return {...state, sending:false, error:action.error, sent:false}
    default:
      return state
  }
}
