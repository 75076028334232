import actionTypes from '../constants';
import Cookie from 'js-cookie';
import ApiMessenger from '../helpers/services/api';
import Notification from './notification';
let Notifier = new Notification();
//import history from '../helpers/history';


//create instance of ApiMessenger
let api = new ApiMessenger();


export function join(user){

  return dispatch => {
    dispatch(request(user))
    api.join(user).then((newUser)=>{
      dispatch(success(newUser.data));
      let credentials = {
        username:user.username,
        password:user.password
      }
       dispatch(auth(credentials));
    }).catch((error)=>{
      if(!error.response){
        dispatch(failure("Network Error"));
      }else{
        dispatch(failure(error.response.data.error));
      }
    })
  }

  function request(user){return{type:actionTypes.CREATE_USER_REQUEST, user}}
  function success(newUser){return{type:actionTypes.CREATE_USER_SUCCESS, newUser}}
  function failure(error){return{type:actionTypes.CREATE_USER_FAILURE, error}};
}





export function auth(credentials){

  return dispatch => {
    dispatch(request(credentials))
    api.auth(credentials).then((user)=>{
      let {token} = user.data;
      Cookie.set('token', token);
      dispatch(success(token));
      window.location.href = "/welcome";
    }).catch((error)=>{
      if(!error.response){
        dispatch(failure("Network Error"));
      }else{
        Cookie.remove('token')
        dispatch(failure(error.response.data.error));
      }
    })
  }

  function request(credentials){return{type:actionTypes.SIGN_IN_REQUEST, credentials}}
  function success(token){return{type:actionTypes.SIGN_IN_SUCCESS, token}}
  function failure(error){return{type:actionTypes.SIGN_IN_FAILURE, error}};
}




export function getIdentity(){
  return dispatch => {
    let token = Cookie.get('token');
    dispatch(request(token));
    api.getIdentity(token).then((identity)=>{
      dispatch(success(identity.data));
    }).catch((error)=>{
      Cookie.remove('token')
      window.location.href = "/login"
      dispatch(failure(error.message));
    })
  }

  function request(token){return{type:actionTypes.IDENTITY_REQUEST, token}}
  function success(identity){return{type:actionTypes.IDENTITY_SUCCESS, identity}}
  function failure(error){return{type:actionTypes.IDENTITY_FAILURE, error}};
}




export function signOut(){
  return dispatch => {
    Cookie.remove('token');
    dispatch(kill({}));
  }

  function kill(data){return{type:actionTypes.SIGN_OUT, data}}
}





export function requestResetCode(email){
  return dispatch => {
    dispatch(request({email}));
    api.requestResetCode({email}).then((response)=>{
      let {data} = response;
      if(data){
        dispatch(success(data));
      }
    }).catch((error)=>{
      if(!error.response){
        dispatch(failure("Network Error"));
      }else{
        dispatch(failure(error.response.data.error));
      }
    })
  }

  function request(request){return{type:actionTypes.PASSWORD_RESET_CODE_REQUEST, request}};
  function success(data){return{type:actionTypes.PASSWORD_RESET_CODE_SUCCESS, data}};
  function failure(error){return{type:actionTypes.PASSWORD_RESET_CODE_FAILURE, error}};
}



export function confirmResetCode(data){
  return dispatch => {
    dispatch(request(data));
    api.confirmResetCode(data).then((response)=>{
      let {data} = response;
      if(data){
        dispatch(success(data));
      }
    }).catch((error)=>{
      if(!error.response){
        dispatch(failure("Network Error"));
      }else{
        dispatch(failure(error.response.data.error));
      }
    })
  }


  function request(request){return{type:actionTypes.CONFIRM_PASSWORD_RESET_CODE_REQUEST, request}};
  function success(data){return{type:actionTypes.CONFIRM_PASSWORD_RESET_CODE_SUCCESS, data}};
  function failure(error){return{type:actionTypes.CONFIRM_PASSWORD_RESET_CODE_FAILURE, error}};
}




export function resetPassword(data){
  return dispatch => {
    dispatch(request(data));
    api.resetPassword(data).then((response)=>{
      let {data} = response;
      if(data){
        let report = {title:"Success", report:data.message}
        Notifier.reportSuccess(report);
        dispatch(success(data))
      }
    }).catch((error)=>{
      if(!error.response){
        dispatch(failure("Network Error"));
      }else{
        dispatch(failure(error.response.data.error));
      }
    })
  }



  function request(request){return{type:actionTypes.PASSWORD_RESET_REQUEST, request}};
  function success(data){return{type:actionTypes.PASSWORD_RESET_SUCCESS, data}};
  function failure(error){return{type:actionTypes.PASSWORD_RESET_FAILURE, error}};
}





// © plusound 2020
